import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import {Link} from "gatsby"

function ContactSentPage() {
  return (
    <Layout>
      <SEO
        title="Contact Us"
        keywords={["UARS", "Upper Airway Resistance Syndrome", "Sleep breathing disorders"]}
      />
      <section>
        <label
          className="block font-bold mb-2 text-xs uppercase"
          htmlFor="last-name"
        >
          We got your message! We'll get back to you shortly!
        </label>

        <Link to='/'>
          <button
            className="border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 rounded text-sm text-white">
            Go back home
          </button>
        </Link>
      </section>
    </Layout>
  );
}

export default ContactSentPage;
